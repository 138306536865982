import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import classNames from "classnames"
import { FluidObject } from "gatsby-image"
import { RoFPageQuery } from "../../../graphql/types"
import { defaultMetadata } from "../../core/constants"
import styles from "./rof.module.scss"
import { Translation } from "@features/rof/components/download/types"
import { CallToAction } from "@components/call-to-action/call-to-action"
import AdditionalInfo from "@components/AdditionalInfo/additionalInfo"
import { Download } from "@features/rof/components/download/download"
import { PageProps } from "@src-types/page"
import { Page } from "@components/page/page"
import {
  FounderDetails,
  FounderResource
} from "@features/rof/components/FounderDetails"

export const RoFPage: FunctionComponent<PageProps<RoFPageQuery>> = (props) => {
  const {
    seo,
    heroImage,
    heroOverline,
    heroTitle,
    heroDescription,
    heroPrimaryButtonText,
    heroPrimaryButtonUrl,
    heroSecondaryButtonText,
    heroSecondaryButtonUrl,
    downloadTitle,
    downloadDescription,
    downloadTranslations,
    globalOutreachOverline,
    globalOutreachTitle,
    globalOutreachDescription,
    globalOutreachButtonText,
    globalOutreachButtonUrl,
    founderContent,
    founderResourcesOverline,
    founderResources
  } = props?.data?.contentfulTheRaceOfFaithLandingPage || {}

  const metadataOverrides = {
    title: seo?.title || defaultMetadata.title,
    description: seo?.description || defaultMetadata.description,
    openGraphImage: seo?.image?.fixed?.src
  }

  const translations = downloadTranslations?.reduce((acc, cur) => {
    if (cur) {
      acc.push({
        image: cur.image?.fluid?.src || cur?.image?.file?.url || "",
        imageAlt: cur.image?.title || "",
        title: cur.title?.childMarkdownRemark?.rawMarkdownBody || "",
        urlLink: cur.urlLink || ""
      })
    }

    return acc
  }, [] as Array<Translation>)

  const resources = founderResources?.reduce((acc, cur) => {
    if (cur) {
      acc.push({
        image: cur.image?.fluid?.src || "",
        overline: cur.overline || "",
        title: cur.title?.childMarkdownRemark?.rawMarkdownBody || "",
        urlLink: cur.urlLink || ""
      })
    }

    return acc
  }, [] as Array<FounderResource>)

  return (
    <Page
      {...props}
      metadata={{
        ...defaultMetadata,
        ...metadataOverrides
      }}
      isInfoIconShown={true}
    >
      <div className={classNames(styles.section, styles.header)}>
        <CallToAction
          title={heroTitle || ""}
          overline={heroOverline || ""}
          body={heroDescription?.childMarkdownRemark?.rawMarkdownBody || ""}
          buttonText={heroPrimaryButtonText || ""}
          buttonUrl={heroPrimaryButtonUrl || ""}
          buttonTwoText={heroSecondaryButtonText}
          buttonTwoUrl={heroSecondaryButtonUrl}
          imageFluid={heroImage?.fluid as FluidObject}
          variant="tertiary"
        />
      </div>
      <div>
        <Download
          title={downloadTitle?.childMarkdownRemark?.rawMarkdownBody || ""}
          description={
            downloadDescription?.childMarkdownRemark?.rawMarkdownBody || ""
          }
          list={translations}
        />
      </div>
      <div className={styles.globalOutreachContainer}>
        <AdditionalInfo
          overline={globalOutreachOverline || ""}
          title={globalOutreachTitle || ""}
          body={
            globalOutreachDescription?.childMarkdownRemark?.rawMarkdownBody ||
            ""
          }
          buttonText={globalOutreachButtonText || ""}
          buttonUrl={globalOutreachButtonUrl || ""}
        />
      </div>
      <div className={styles.section}>
        <FounderDetails
          content={{
            image: founderContent?.image?.fluid?.src || "",
            overline: founderContent?.overline || "",
            title:
              founderContent?.title?.childMarkdownRemark?.rawMarkdownBody || "",
            description:
              founderContent?.description?.childMarkdownRemark
                ?.rawMarkdownBody || ""
          }}
          overline={founderResourcesOverline || ""}
          resources={resources || []}
        />
      </div>
    </Page>
  )
}

export default RoFPage

export const query = graphql`
  query RoFPage {
    contentfulTheRaceOfFaithLandingPage {
      seo {
        ...Metadata
      }
      heroImage {
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
      heroOverline
      heroTitle
      heroDescription {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      heroPrimaryButtonText
      heroPrimaryButtonUrl
      heroSecondaryButtonText
      heroSecondaryButtonUrl
      downloadTitle {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      downloadDescription {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      downloadTranslations {
        image {
          fluid(maxWidth: 100) {
            ...GatsbyContentfulFluid_noBase64
          }
          file {
            url
          }
          title
        }
        title {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        urlLink
      }
      globalOutreachOverline
      globalOutreachTitle
      globalOutreachDescription {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      globalOutreachButtonText
      globalOutreachButtonUrl
      founderContent {
        image {
          fluid(maxWidth: 600) {
            ...GatsbyContentfulFluid_noBase64
          }
        }
        overline
        title {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        description {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      founderResourcesOverline
      founderResources {
        image {
          fluid(maxWidth: 600) {
            ...GatsbyContentfulFluid_noBase64
          }
        }
        overline
        title {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        urlLink
      }
    }
  }
`
