import React, { FunctionComponent } from "react"
import classNames from "classnames"
import styles from "./download.module.scss"
import { Translation } from "./types"
import { Markdown } from "@components/markdown/markdown"
import { Link } from "@components/link/link"
import { Icon } from "@components/icon/icon"

type Props = {
  title: string
  description: string
  list?: Array<Translation>
}

export const Download: FunctionComponent<Props> = ({
  title,
  list,
  description
}) => {
  return (
    <div className={styles.download}>
      <div className={styles.content}>
        <Markdown className={styles.title} content={title} />
        <Markdown className={styles.description} content={description} />
        {list && (
          <div className={styles.translations}>
            {list.map((block, i) => {
              return (
                <Link
                  className={styles.block}
                  key={`${block.title}-${i}`}
                  to={block?.urlLink}
                  target="_blank"
                >
                  <div className={styles.image}>
                    {block.image && (
                      <img src={block.image} alt={block.imageAlt} />
                    )}
                  </div>
                  <div className={styles.blockText}>
                    <Markdown
                      className={classNames(
                        styles.blockName,
                        styles.externalLinkName
                      )}
                      content={block.title}
                    />
                    <Icon
                      variant="12-external-link"
                      className={styles.externalLinkIcon}
                    />
                  </div>
                </Link>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
