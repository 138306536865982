import React, { FunctionComponent } from "react"
import Image from "gatsby-image"
import classNames from "classnames"
import { Button } from "../button/button"
import { CallToAction as CallToActionProps } from "../../types/call-to-action"
import styles from "./call-to-action.module.scss"
import { Markdown } from "@components/markdown/markdown"

export const CallToAction: FunctionComponent<CallToActionProps> = ({
  variant = "primary",
  title,
  subtitle,
  overline,
  buttonText,
  buttonUrl,
  buttonTwoText,
  buttonTwoUrl,
  body,
  imageFluid
}) => (
  <div className={styles.promo}>
    <div className={classNames(styles.image, styles[variant])}>
      <Image fluid={imageFluid} />
    </div>
    <div className={styles.text}>
      <h5 className={styles.overline}>{overline}</h5>
      <h3 className={styles.title}>{title}</h3>
      {subtitle && <Markdown content={subtitle} className={styles.subtitle} />}
      {body && <Markdown content={body} className={styles.body} />}
      <div className={styles.buttonWrapper}>
        <Button
          to={buttonUrl}
          variant="primary"
          size="md"
          className={styles.button}
        >
          {buttonText}
        </Button>
        {buttonTwoUrl && buttonTwoText && (
          <Button
            to={buttonTwoUrl}
            variant="secondary"
            size="md"
            className={styles.button}
          >
            {buttonTwoText}
          </Button>
        )}
      </div>
    </div>
  </div>
)
