import React from "react"
import styles from "./additionalInfo.module.scss"
import { Button } from "@components/button/button"
import { Markdown } from "@components/markdown/markdown"

export type AdditionalInfoProps = {
  overline?: string
  title: string
  body: string
  buttonText?: string
  buttonUrl?: string
}

function AdditionalInfo(props: AdditionalInfoProps) {
  const { buttonText, buttonUrl, overline, body, title } = props

  const showButton = buttonText && buttonUrl

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        {overline && <div className={styles.overline}>{overline}</div>}
        <h3 className={styles.title}>{title}</h3>
        <Markdown className={styles.description} content={body} />
      </div>
      {showButton && (
        <div className={styles.button}>
          <Button size="sm" variant="primary" to={buttonUrl}>
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  )
}

export default AdditionalInfo
